import React from "react";
import Section from "../Section";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Button = ({ text, empathize, slug }) => (
  <Link
    className={`px-4 py-2 rounded font-semibold cursor-pointer ${
      empathize
        ? "shadow hover:shadow-lg transition bg-gradient-to-r from-primary to-primary-dark text-primary-text"
        : "hover:shadow transition bg-blue-100 text-blue-600"
    }`}
    to={"/season" + slug}
  >
    {text}
  </Link>
);

const SeasonOverview = ({
  title,
  date,
  text,
  image,
  button1,
  to1,
  button2,
  to2,
  right,
  background,
  slug,
}) => (
  <div>
    <Section noPadding background={background} className="border-2 rounded">
      <div className="grid lg:grid-cols-2 gap-8">
        <div className={right && "order-last"}>
          <h1 className="text-4xl">{title}</h1>
          <div className="prose pr-8">
            <p>{date}</p>
            <p>{text}</p>
          </div>
          <div className="mt-8 flex gap-4">
            {button1 && <Button slug={slug} text={button1} empathize />}
            {button2 && <Button text={button2} />}
          </div>
        </div>

        <GatsbyImage className="rounded" alt={title + " Image"} image={image} />
      </div>
    </Section>
  </div>
);

export default SeasonOverview;
