import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { graphql } from "gatsby";
import SeasonOverview from "../components/SeasonOverview";
import PageHeader from "../components/PageHeader";

const Seasons = ({ data }) => {
  console.log(data);
  const seasons = data.seasons.nodes;

  return (
    <Layout>
      <Section>
        <PageHeader
          title="Past Seasons"
          tagline="Season(ings)"
          desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"
        />
      </Section>
      {seasons.map((event, i) => (
        <Section>
          <SeasonOverview
            date={event.frontmatter.date}
            right={i % 2}
            key={event.id}
            title={event.frontmatter.title}
            image={event.frontmatter.cover.childImageSharp.gatsbyImageData}
            text={event.frontmatter.summary}
            slug={event.fields.slug}
            button1="Learn more"
            // button2="Download Scores"
          />
        </Section>
      ))}
    </Layout>
  );
};

export default Seasons;

export const pageQuery = graphql`
  query SeasonsQuery {
    seasons: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/seasons/" } }
      sort: { order: DESC, fields: [frontmatter___index] }
    ) {
      nodes {
        id
        frontmatter {
          title
          date
          summary
          cover {
            childImageSharp {
              gatsbyImageData(
                aspectRatio: 1.333333
                transformOptions: { cropFocus: NORTH }
              )
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`;
